.App {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background: #ececec;
  padding: 0;
  margin: 0;
}

.game {
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: fit-content;
  margin: 0;
}

.gameBoard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
}

.row {
  padding: 3px;
  display: flex;
  justify-content: center;
  width: fit-content;
}

header {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 16px;
  border-bottom: 1px solid;
}

.menu-left {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  width: 70px;
  justify-content: flex-start;
}

.menu-right {
  display: flex;
  width: 70px;
  justify-content: flex-end;
}

.row.active {
  background: #cccccc;
}

.tile {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #b5b5b5;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  padding: 5px;
  margin: 2px;
}

.correct {
  background: #4ade80 !important;
}

.present {
  background: #fbbf24 !important;
}

.not-found {
  background: #cccccc !important;
}
